<template>
  <div class="logo">
    <a href="/">
      <img src="@/assets/logo.png" alt="Logo" />
    </a>

  </div>
<!--  <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" >-->
<!--    <el-menu-item index="hot_selection" @click="goToHome('hot_selection')">热点精选</el-menu-item>-->
<!--    <el-menu-item index="experts_view" @click="goToHome('experts_view')">专家看盘</el-menu-item>-->
<!--    <el-menu-item index="real_positions" @click="goToHome('real_positions')">带单实盘</el-menu-item>-->
<!--    <el-menu-item index="advanced_strategies" @click="goToHome('advanced_strategies')">波动率策略</el-menu-item>-->
<!--    <el-menu-item index="new-narratives" @click="goToHome('new-narratives')" >新叙事新机会</el-menu-item>-->
<!--    <el-menu-item index="aboutus" @click="goToAboutUs">关于我们</el-menu-item>-->
<!--  </el-menu>-->
  <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" >
<!--    <el-menu-item index="hot_selection">热点精选</el-menu-item>-->
    <el-menu-item index="experts_view">AI看盘</el-menu-item>
    <el-menu-item index="real_positions">带单实盘</el-menu-item>
    <el-menu-item index="vaults">策略金库</el-menu-item>
    <el-menu-item index="advanced_strategies">策略实盘表现</el-menu-item>
    <el-menu-item index="new-narratives">新叙事新机会</el-menu-item>
    <el-menu-item index="about_us">关于我们</el-menu-item>
  </el-menu>
<!--  <el-input placeholder="Search" prefix-icon="el-icon-search" class="search-input" />-->
<!--  <el-avatar icon="el-icon-user"></el-avatar>-->

</template>
<script>
import {ElMenu, ElMenuItem} from 'element-plus'
import { nextTick } from 'vue';
export default {
  components: {
    ElMenu,
    ElMenuItem,
  },
  data() {
    return {
      activeIndex: "1",
    };
  },
  methods: {
    windowScrollTo(key){
      const section = document.getElementById(key);
      const header = document.getElementById("header"); // 获取el-header
      if (section && header) {
        // 获取该元素的距离页面顶部的实际偏移量
        const topOffset = section.getBoundingClientRect().top + window.pageYOffset;

        // 手动滚动页面，添加导航栏的高度（假设导航栏高度为60px）
        // const headerHeight = 60; // 替换为你的导航栏高度
        const headerHeight = header.offsetHeight;
        window.scrollTo({
          top: topOffset - headerHeight, // 滚动时减去导航栏高度
          behavior: "smooth", // 平滑滚动
        });
      }
    },
    handleSelect(key) {
      if(key === "about_us"){
        this.$router.push({ name: 'AboutUsView' }).then(() => {
          // 等待页面渲染完成
          nextTick(() => {
            // this.windowScrollTo(key)
            this.activeIndex = key
          });
        });
      }else if (key === "vaults"){
        this.$router.push({ name: 'StrategyVaults' }).then(() => {
          // 等待页面渲染完成
          nextTick(() => {
            // this.windowScrollTo(key)
            this.activeIndex = key
          });
        });
      }else{
        this.$router.push({ name: 'home' }).then(() => {
          // 等待页面渲染完成
          nextTick(() => {
            this.windowScrollTo(key)
          });
        });
      }
    },
  },
};
</script>

<style scoped>

.logo img {
  height: 60px;
}


.search-input {
  width: 200px;
  margin-right: 20px;
}

.el-menu-demo {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.el-menu-item {
  margin-right: 30px;
}

.el-menu-item.is-active {
  color: #0D83FE; /* 激活状态的菜单项颜色 */
}

.el-menu-item.is-active::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #0D83FE; /* 下划线颜色 */
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>