<template>
  <div class="expert-analysis">

    <div class="highlights-header">
      <h2 class="section-title">{{ title }}</h2>
      <div class="section-description subtitle">
        <span>{{ description }}</span>
        <router-link to="/strategy-detail" class="view-all" style="color: #409eff; text-decoration: none;">
          查看更多 >
        </router-link>
      </div>
    </div>

    <el-row :gutter="20">
      <!-- 小屏，每行显示 1 列 -->
      <!-- 中小屏，每行显示 2 列 -->
      <!-- 中屏，每行显示 3 列 -->
      <!-- 大屏，每行显示 4 列 -->
      <!-- 超大屏，每行显示 6 列 -->
      <el-col
          v-for="(chart, index) in charts"
          :key="index"
          :xs="24"
          :sm="12"
          :md="8"
          :lg="8"
          :xl="8"
      >
<!--      <el-col :span="8" v-for="(chart, index) in charts" :key="index">-->
        <el-card :body-style="{ padding: '0px' }">
          <div class="chart-container" v-if="chart.data">
            <line-chart :data="chart.data" :options="chartOptions" />
          </div>
          <div class="chart-info">
            <span class="chart-title">{{ chart.title }}</span>
            <div class="chart-author">
              <!-- <el-avatar :src="chart.authorAvatar"></el-avatar>
              <span>{{ chart.author }}</span> -->
              <span>{{ chart.ROI }} </span>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Line as LineChart } from 'vue-chartjs'
import { zhCN } from 'date-fns/locale';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js'
import axios from "axios";
import 'chartjs-adapter-date-fns';
import { ElCard, ElRow, ElCol } from 'element-plus'
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale)

export default {
  name: 'AdvancedStrategies',
  components: { LineChart, ElCard, ElRow, ElCol },
  data() {
    return {
      title: '策略实盘表现',
      description: '利用期权等衍生工具，拥抱加密货币的高波动性',
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              locale: zhCN,
              tooltipFormat: 'yyyy-MM-dd',
              displayFormats: {
                day: 'd'
              }
            },
            ticks: {
              callback: function(value, index, values) {
                // 获取日期
                const date = new Date(value);
                const day = date.getDate(); // 获取日期
                const month = date.getMonth(); // 获取月份
                if(index === 0){
                  return `${day}`;
                }
                const oldMonth = new Date(values[index - 1].value).getMonth(); // 确保直接访问的是日期值
                if (oldMonth !== month) {
                  // 如果是新月份，显示月份和日期
                  return `${month + 1}月`;
                } else {
                  // 否则只显示日期
                  return `${day}`;
                }
              }
            }
          }
        }
      },
      charts: [],  // 用于存储转换后的图表数据

    }
  },
  mounted() {
    this.fetchPredictPrices();
  },
  methods: {
    async fetchPredictPrices() {
      try {
        const response = await axios.get('https://ai.zkware.net/api/v1/advanced_strategies'); // 替换为你的 API URL
        this.charts = this.convertToCharts(response.data.data)
      } catch (error) {
        console.error('Error fetching news data:', error);
      }
    },
    convertToCharts(data) {
      const chartTemplates = [];
      Object.keys(data).forEach(symbol => {
        let symbolData = data[symbol];
        const last14Dates = symbolData.dates.slice(-14).map(date => new Date(date));  // 取后 14 个日期
        const last14Amounts = symbolData.amount.slice(-14);  // 确保数据和日期一致
        let symbolStr = symbol; // 默认标题
        let closeRoi = symbolData["close_roi"];
        if (last14Dates.length !== last14Amounts.length) {
          console.warn(`Data length mismatch for symbol: ${symbolStr}`);
          return; // 数据不一致时，跳过当前 symbol 的处理
        }
        const chart = {
          title: `${symbolStr}`,
          ROI:closeRoi,
          data: {
            labels: last14Dates,
            datasets: [
              {
                label: 'USDT净值',
                data: last14Amounts,
                borderColor: '#0D83FE',
                fill: true,
                tension: 0.4
              }
            ]
          }
        };
        if(!chart.title.includes("已结束")){
          chartTemplates.push(chart);
        }
      });
      chartTemplates.sort((a, b) => {
        if (a.title.includes("已结束") && !b.title.includes("已结束")) {
          return 1; // a 在 b 后面
        }
        if (!a.title.includes("已结束") && b.title.includes("已结束")) {
          return -1; // a 在 b 前面
        }
        return 0; // 不改变顺序
      });
      return chartTemplates;
    }
  }

}

</script>

<style scoped>
.expert-analysis {
  margin-bottom: 20px;
}
.section-title {
  font-size: 24px;
  margin-bottom: 10px;
}
.section-description {
  color: #666;
  margin-bottom: 20px;
}
.subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
  font-size: 14px;
}
.chart-container {
  height: 200px;
}
.chart-info {
  padding: 14px;
}
.chart-title {
  font-weight: bold;
}
.chart-author {
  display: flex;
  align-items: center;
  margin-top: 10px;
  height: 20px;
}
.chart-author .el-avatar {
  margin-right: 8px;
}

.view-all {
  font-size: 14px;
}
</style>