<template>
  <div class="content-section">

    <div class="highlights-header">
      <h2 class="section-title">{{ title }}</h2>
      <div class="section-description subtitle">
        <p>{{ description }}</p>
<!--        <router-link to="/all-news" class="view-all" style="color: #409eff; text-decoration: none;">-->
<!--          查看更多 >-->
<!--        </router-link>-->
      </div>
    </div>

    <el-row :gutter="20" class="flow-box">
      <el-col
          v-for="(item, index) in items"
          :key="index"
          :xs="24"
          :sm="12"
          :md="8"
          :lg="8"
          :xl="8"
      >
<!--      <el-col :span="8" v-for="(item, index) in items" :key="index">-->
        <el-card class="fixed-size-card">
          <div class="card-content">
            <span class="title">{{ item.title }}</span>
            <span class="date-time">{{ item.date_time }}</span>
            <p class ="paper" v-html="item.description"></p>
          </div>
        </el-card>

      </el-col>
    </el-row>
  </div>

</template>

<script>
import axios from "axios";
// import content from '../src/narratives/1.md';
import marked from 'marked';
import {ElCard, ElCol, ElRow} from 'element-plus'
export default {
  name: 'ContentSection',
  components: { ElCard, ElRow, ElCol },
  data() {
    return {
      title: '热点叙事',
      description: '加密经济永远不缺新的叙事，远的有早几年的Defi Summer、加密猫、GameFi、平台币、新Layer1、DePIN、ZKRollup等等，近的有23年的比特币铭文，' +
          '24年至今加密经济虽然缺少跟去年比特币铭文相提并论的新叙事逻辑，但也还围绕着"BTC减半"、"现货ETF上市"、"降息"、"各种诉讼赔偿结案"等主旋律，出现了诸如老币换发新春（SOL）、' +
          'Layer1新王有望引领新一轮牛市等热点或舆情，ZKware LAB持续跟踪相关赛道的投资机会，并无偿分享给社区。',
      items: []
    }
  },

  created() {
    this.fetchMarkdownContent();
  },
  methods: {
    async fetchMarkdownContent() {
      try {
        // 假设你的 Markdown 文件位于 public 目录下
        // const files = import.meta.glob('/narratives/v3/*.md');
        const folderPath = '/narratives/v3/';
        // todo 动态获取
        const files = [
            '2024-12-01/ALEO.md',
          '2024-12-01/SOL.md',
          '2024-12-01/DOGE.md',
          '2024-12-01/SUI.md',
          '2024-12-01/XRP.md',
          '2024-12-05/AI赛道.md',
          '2024-12-05/RWA赛道.md',
          '2024-12-18/VC币和meme币互不接盘.md',
          '2025-02-24/AMM-LP_VS_网格_配对.md',
          '2025-02-24/认识AMM-LP的无常损失.md']; // 假设您已经知道文件路径，或者从服务器端获取
        const items = [];
        for (const filePath of files) {
          // const module = await files[path]();
          const response = await axios.get(`${folderPath}${filePath}`);
          const content = response.data;
          // console.log(module)
          // const content = module.default; // 获取文件内容
          const title = this.extractFileName(filePath); // 假设您有一个函数来提取标题
          const date_time = this.extractFolderName(filePath); // 假设您有一个函数来提取日期
          items.push({ title, description: marked(content), date_time });
        }
        this.items = items;
        // 使用 Array.prototype.sort() 进行排序
        this.items.sort((a, b) => {
          // 将日期字符串转换为 Date 对象进行比较
          const dateA = new Date(a.date_time);
          const dateB = new Date(b.date_time);
          return dateB - dateA; // 降序排序
        });
        // this.handleImagePaths();
      } catch (error) {
        console.error("Error loading markdown content:", error);
      }
    },
    extractFileName(path) {
      const parts = path.split('/');
      const fileNameWithExtension = parts[parts.length - 1]; // 获取文件名带扩展名
      return fileNameWithExtension.replace('.md', ''); // 去除 .md 扩展名
    },
    // 提取上一层文件夹名
    extractFolderName(path) {
      const parts = path.split('/');
      return parts[parts.length - 2]; // 获取倒数第二部分作为日期时间
    },
    handleImagePaths() {
      const imgTags = document.querySelectorAll('img');
      imgTags.forEach(img => {
        // 确保图片的路径是以 public 为基准的
        if (img.src.startsWith('./')) {
          img.src = `/narratives/v3/images${img.src.substring(1)}`;
        }
      });
    }
  }



}
</script>

<style scoped>
.content-section {
  margin-bottom: 10px;
}
.section-title {
  font-size: 24px;
  margin-bottom: 10px;
}
.section-description {
  color: #666;
  margin-bottom: 20px;
}
.subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
  font-size: 14px;
}
.flow-box {
  display: flow;
}
.fixed-size-card {
  /* height: 400px; */
  display: inline-block;
  margin-bottom: 20px;
  /* flex-direction: column; */
}

.card-content {
  display: inline-block;
  min-height: 200px;
  flex-grow: 1;
  overflow-y: auto;
}

.card-footer {
  padding: 10px;
  border-top: 1px solid #ebeef5;
  font-size: 0.9em;
  color: #606266;
}

.news-item {
  margin-bottom: 10px;
}

.link {
  display: block;
  text-align: left;
  color: #409EFF;
  text-decoration: none;
  font-size: 0.95em;
  line-height: 1.4;
}

.link:hover {
  text-decoration: underline;
}

.date {
  font-size: 0.85em;
  color: #909399;
  margin-left: 5px;
}

.date-time {
  float: right;
  color: #909399;
}
.title {
  font-weight: bold;
}
.paper {
  text-align: left;
}

.card-image {
  width: 100%; /* 图片宽度自适应卡片宽度 */
  height: 100%; /* 图片高度自适应卡片高度 */
  object-fit: contain; /* 或者 cover，视需求而定 */
  display: block; /* 去掉图片底部的间隙 */
}

.fixed-size-card .card-content img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
</style>